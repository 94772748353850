<template>
  <div class="px-2">
    <filter-slot
      :filter="[]"
      :total-rows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :start-page="FilterSlot.startPage"
      :to-page="FilterSlot.toPage"
      :filter-principal="FilterSlot.filterPrincipal"
      @reload="$refs['claimsTable'].refresh()"
    >
      <template #buttons v-if="[1, 2, 17].includes(currentUser.role_id)">
        <div class="ml-2">
          <b-button
            variant="primary"
            @click="openCreateModal"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
          >
            <tabler-icon icon="PlusIcon" />
            Create New {{ tabSelected }}
          </b-button>
        </div>
      </template>
      <b-table
        ref="claimsTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="myFields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.status == 'ACTIVE' ? 'light-success' : 'light-warning'
            "
            >{{ data.item.status }}</b-badge
          >
          <feather-icon
            v-if="validateStatusAndRefund(data.item)"
            :icon="
              data.item.status == 'ACTIVE' ? 'ShieldIcon' : 'ShieldOffIcon'
            "
            class="clickable ml-1"
            :class="
              data.item.status == 'ACTIVE' ? 'text-success' : 'text-warning'
            "
            size="17"
            @click="changeStatus(data.item)"
            v-b-tooltip.hover.top="
              data.item.status == 'ACTIVE' ? 'Disable' : 'Enable'
            "
          />
          <feather-icon
            v-else
            :icon="
              data.item.status == 'ACTIVE' ? 'ShieldIcon' : 'ShieldOffIcon'
            "
            class="clickable ml-1"
            :class="data.item.status == 'ACTIVE' ? 'text-grey' : 'text-warning'"
            style="cursor: default"
            size="17"
            v-b-tooltip.hover.top="'Not editable'"
          />
        </template>
        <template #cell(created_at)="{ value }">
          <span> {{ value | myGlobalDay }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              icon="ListIcon"
              size="16"
              @click="trackingModal(data.item)"
              class="text-primary clickable"
            />
            <b-button
              :disabled="['Status to hold', 'REFUND'].includes(data.item.item)"
              variant="outline"
              @click="editModal(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                :class="
                  data.item.item == 'Status to hold' ||
                  data.item.item == 'REFUND'
                    ? 'text-grey'
                    : 'text-info'
                "
                v-b-tooltip.hover
                :title="
                  data.item.item == 'Status to hold' ||
                  data.item.item == 'REFUND'
                    ? 'Not editable'
                    : null
                "
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </filter-slot>
    <item-modal
      v-if="showCreateModal"
      @close="closeCreateModal"
      @refresh="refreshTable()"
      :type="type"
      :claimData="claimData"
      :tabSelected="tabSelected"
    >
    </item-modal>
    <TrackingModal
      v-if="showTrackingModal"
      :itemId="itemId"
      :itemType="tabSelected"
      @close="closeTrackingModal"
    >
    </TrackingModal>
  </div>
</template>
<script>
import Fields from "@/views/quality/views/settings/views/data/fields.js"
import SettingsService from "@/views/quality/views/settings/service/settings.service.js"
import CreateItemModal from "@/views/quality/views/settings/views/modal/CreateItemModal.vue"
import TrackingModal from "./modal/TrackingModal.vue"
import { mapGetters } from "vuex"
export default {
  components: {
    "item-modal": CreateItemModal,
    TrackingModal,
  },
  data() {
    return {
      source: "",
      status: "",
      showCreateModal: false,
      Fields,
      isBusy: false,
      type: null,
      claimData: {},
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      showTrackingModal: false,
      itemId: null,
      itemType: "",
      dataClaims: [],
    }
  },
  props: {
    tabSelected: String,
  },
  computed: {
    myFields: function () {
      this.Fields[2].visible = this.tabSelected == "sanction"
      this.Fields[5].visible = [1, 2, 17].includes(this.currentUser.role_id)
      return this.Fields.filter((field) => field.visible)
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    createModal() {
      this.type = "Create"
      this.showCreateModal = true
    },
    closeCreateModal() {
      this.showCreateModal = false
      this.claimData = {}
    },
    editModal(data) {
      this.type = "Edit"
      this.showCreateModal = true
      this.claimData = data
    },
    closeTrackingModal() {
      this.showTrackingModal = false
    },
    openCreateModal() {
      this.type = "Create"
      this.showCreateModal = true
      this.claimData = null
    },
    trackingModal(item) {
      this.itemId = item.id
      this.showTrackingModal = true
    },
    async myProvider() {
      this.isBusy = true
      let data
      const formdata = {
        tabSelected: this.tabSelected,
        search: this.FilterSlot.filterPrincipal.model,
        page: this.FilterSlot.paginate.currentPage,
        perPage: this.FilterSlot.paginate.perPage,
      }
      try {
        data = await SettingsService.getItemClaims(formdata)
        this.FilterSlot.paginate.currentPage = data.current_page
        this.FilterSlot.paginate.perPage = data.per_page
        this.FilterSlot.totalRows = data.total
        this.FilterSlot.toPage = data.to
        this.isBusy = false
        this.dataClaims = data.data
      } catch (error) {
        console.log(error)
      }
      return this.dataClaims
    },
    async changeStatus(data) {
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the status?`
      )

      if (confirm.isConfirmed) {
        let newStatus = data.status == "ACTIVE" ? "INACTIVE" : "ACTIVE"
        const formdata = {
          id: data.id,
          item: data.item,
          status: newStatus,
          tabSelected: this.tabSelected,
          levelId: data.level_id,
          userId: this.currentUser.user_id,
          level: data.level,
        }
        try {
          let data = await SettingsService.updateItem(formdata)
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            )
            this.refreshTable()
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            )
          }
        } catch (error) {
          this.isActive = true
          this.showErrorSwal(error)
        }
      }
    },
    refreshTable() {
      this.$refs.claimsTable.refresh()
    },
    validateStatusAndRefund(data) {
      return (data.item === "Status to hold" || data.item === "REFUND") &&
        data.created_by == "System T&I"
        ? false
        : true
    },
  },
}
</script>