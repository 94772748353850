import { amgApi } from "@/service/axios"

class SettingService {
  async saveItem(params) {
    try {
      const { data } = await amgApi.post("/quality/settings/save-item", params)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateItem(params) {
    try {
      const { data } = await amgApi.post(
        "/quality/settings/update-item",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getItemClaims(params) {
    try {
      const { data } = await amgApi.post(
        "/quality/settings/get-item-claims",
        params
      )
      
      return data
    } catch (error) {
      throw error
    }
  }
  async getLevelClaim() {
    try {
      const { data } = await amgApi.get("/quality/settings/get-level-claim")
      return data
    } catch (error) {
      throw error
    }
  }
  async updateFollowDays(params) {
    try {
      const { data } = await amgApi.post(
        "/quality/settings/update-follow-days",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async getAllLevelClaims() {
    try {
      const dataset = await amgApi.get(
        "/quality/settings/get-all-level-claims"
      )
      return dataset.data.data
    } catch (error) {
      throw error
    }
  }

  async getSettingItemsTrack(params){
    try {
      const data = await amgApi.post('quality/settings/get-setting-items-track', params);
      return data
    } catch (error) {
      throw error;
    }
  }

  async getSettingDetailsQuestion(params){
    try {
      const data = await amgApi.post('quality/settings/get-setting-items-track', params);
      return data
    } catch (error) {
      throw error;
    }
  }
}

export default new SettingService()
