export default [
    {
        key: "item",
        label: "Name",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "question",
        label: "Question",
        tdClass: "pt-1",
        visible: false,
    },
    {
        key: "weight",
        label: "Weight",
        tdClass: "pt-1",
        visible: false,
    },
    {
        key: "status",
        label: "Status",
        tdClass: "pt-1 text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "level",
        label: "Level",
        tdClass: "pt-1 text-center",
        thClass: "text-center",
        visible: false,
    },
    {
        key: "old_follow_up_days",
        label: "Old value",
        tdClass: "pt-1 text-center",
        thClass: "text-center",
        visible: false,
    },
    {
        key: "follow_up_days",
        label: "New value",
        tdClass: "pt-1 text-center",
        thClass: "text-center",
        visible: false,
    },
    {
        key: "user",
        label: "Updated by",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "created_at",
        label: "Created at",
        tdClass: "pt-1",
        visible: true,
    },
]
