<template>
    <b-modal v-model="showModal" size="sm" :title="titleModal" @hide="closeModal" no-close-on-backdrop>
        <label for="input-live">Name:</label>
        <ValidationObserver ref="observer">
            <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-input
                id="input-live"
                v-model="item"
                @keypress.enter="saveItem()"
                autofocus
                ></b-form-input>
                <div v-if="errors[0]" class="text-error-sn text-warning">Name {{errors[0]}}</div>
            </ValidationProvider>
            <template v-if="tabSelected == 'sanction'">
              <label for="input-live">Level:</label>
              <ValidationProvider rules="required" v-slot="{errors}">
                <v-select 
                  :multiple="type === 'Edit' ? null : true"
                  label="level_name"
                  v-model="levelId"
                  class="flex-grow-1 email-to-selector"
                  :options="levels"
                  :reduce="(level) => level.id"
                  @input="changeLevel($event)"
                  :disabled="type === 'Edit' "
                > 
                </v-select >
                <div v-if="errors[0]" class="text-error-sn text-warning">Level {{errors[0]}}</div>
              </ValidationProvider>
            </template>
        </ValidationObserver>
        <template #modal-footer>
            <b-button
                variant="primary"
                @click="saveItem()"
                active: isActive
            >
                {{buttonValue}}
            </b-button>
            <b-button
                variant="secondary"
                @click="closeModal"
            >
                Cancel
            </b-button>
        </template>
    </b-modal>

</template>
<script>
import { mapGetters } from "vuex";
import SettingsService from "@/views/quality/views/settings/service/settings.service.js";
import vSelect from "vue-select";

export default {
  components:{
    vSelect
  },
  data() {
    return {
      showModal: false,
      item: "",
      isActive: true,
      id: null,
      status: 1,
      levels: [],
      levelId: null,
      level: null,
      levelSelected: null,
    };
  },
  props: {
    type: String,
    claimData: Object,
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    titleModal: function () {
      let modalType = this.type === "Edit" ? "UPDATE" : "CREATE";
      let titleModal =
        modalType + " " + this.tabSelected.toUpperCase() + " OF CLAIM";
      return titleModal;
    },
    buttonValue: function () {
      return this.type === "Edit" ? "Update" : "SAVE";
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.valueProp();
    },
    closeModal() {
      this.$emit("close");
    },
    changeLevel(levelId){
      if(!Array.isArray(levelId))
        this.levelSelected = this.levels.find(level => level.id == levelId).level_name;
    },
    async saveItem() {
      let isValid = await this.$refs["observer"].validate();
      if (isValid) {
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) {
          return;
        }
        const formdata = {
          id: this.id,
          item: this.item,
          status: this.status,
          tabSelected: this.tabSelected,
          userId: this.currentUser.user_id,
          levelId: this.levelId,
          level: this.levelSelected
        };
        this.isActive = false;
        try {
          this.addPreloader();
          let methodService = this.type == "Edit" ? "updateItem" : "saveItem";
          let data = await SettingsService[methodService](formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
          this.$emit("refresh");
          this.$emit("close");
        } catch (error) {
          this.isActive = true;
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    valueProp() {
      if (this.claimData) {
        this.item = this.claimData.item;
        this.id = this.claimData.id;
        this.status = this.claimData.status;
        this.level = this.claimData.level;
        if(this.tabSelected == 'sanction') {
          this.levelId = this.claimData.level_id;
        }
      }
    },
    async getLevels(){
      if(this.tabSelected == "sanction"){
        try {
          let data = await SettingsService.getAllLevelClaims();
          this.levels = data;
        } catch (error) {
          throw error;
        }
      }
    }
  },
  created() {
    this.openModal();
    this.getLevels();
  },
};
</script>