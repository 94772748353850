var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","hide-footer":"","title":"TRACKING LIST"},on:{"hide":_vm.closeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"table-responsive"},[_c('b-table',{ref:"trackingTable",staticClass:"blue-scrollbar",attrs:{"items":_vm.myProvider,"fields":_vm.myFields,"primary-key":"id","responsive":"sm","show-empty":"","sticky-header":"50vh","busy":_vm.isBusy,"no-provider-filtering":""},scopedSlots:_vm._u([{key:"cell(item)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).item)+" ")])]}},{key:"cell(question)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).question)+" ")])]}},{key:"cell(weight)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).weight)+" ")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('b-badge',{attrs:{"variant":_vm.jsonParsing(item.detail).status == 'ACTIVE' ? 'light-success' : 'light-warning'}},[_vm._v(_vm._s(_vm.jsonParsing(item.detail).status))])],1)]}},{key:"cell(follow_up_days)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).follow_up_days)+" days ")])]}},{key:"cell(old_follow_up_days)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).old_follow_up_days)+" days ")])]}},{key:"cell(updated_by)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"cell(level)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.jsonParsing(item.detail).level)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.created_at))+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }