<template>
    <div>
        <b-modal v-model="showModal" size="lg" hide-footer title="TRACKING LIST" @hide="closeModal">
            <div class="table-responsive">
                <b-table
                ref="trackingTable"
                :items="myProvider"
                :fields="myFields"
                primary-key="id"
                responsive="sm"
                show-empty
                sticky-header="50vh"
                class="blue-scrollbar"
                :busy="isBusy"
                no-provider-filtering
                >
                    <template #cell(item)="{item}">
                        <div>
                            {{jsonParsing(item.detail).item}}
                        </div>
                    </template>
                    <template #cell(question)="{item}">
                        <div>
                            {{jsonParsing(item.detail).question}}
                        </div>
                    </template>
                    <template #cell(weight)="{item}">
                        <div>
                            {{jsonParsing(item.detail).weight}}
                        </div>
                    </template>
                    <template #cell(status)="{item}">
                        <div>
                            <b-badge
                                :variant="
                                jsonParsing(item.detail).status == 'ACTIVE' ? 'light-success' : 'light-warning'
                                "
                                >{{ jsonParsing(item.detail).status }}</b-badge
                            >
                        </div>
                    </template>
                    <template #cell(follow_up_days)="{item}">
                        <div>
                            {{ jsonParsing(item.detail).follow_up_days }} days
                        </div>
                    </template>
                    <template #cell(old_follow_up_days)="{item}">
                        <div>
                            {{ jsonParsing(item.detail).old_follow_up_days }} days
                        </div>
                    </template>
                    <template #cell(updated_by)="{item}">
                        <div>
                            {{item.user}}
                        </div>
                    </template>
                    <template #cell(level)="{item}">
                        <div>
                            {{jsonParsing(item.detail).level}}
                        </div>
                    </template>
                    <template #cell(created_at)="{item}">
                        <div>
                            {{item.created_at | myGlobalDay}}
                        </div>
                    </template>
                </b-table>
            </div>
        </b-modal>
    </div>
</template>
<script>
import SettingService from "@/views/quality/views/settings/service/settings.service.js";
import TrackingFields from "@/views/quality/views/settings/views/data/tracking.fields.js";
export default {
    data() {
        return {
            showModal: false,
            isBusy: false,
            TrackingFields,
        }
    },
    mounted(){
        this.showModal = true;
        this.myProvider();
    },
    props:{
        itemId: Number,
        itemType: String,
    },
    computed:{
        myFields: function(){
            this.TrackingFields[4].visible = this.itemType == 'sanction';

            this.TrackingFields[5].visible = this.itemType == 'follow days';
            this.TrackingFields[6].visible = this.itemType == 'follow days';
            this.TrackingFields[0].visible = this.itemType == 'follow days' ? false: this.TrackingFields[0].visible;
            this.TrackingFields[3].visible = this.itemType == 'follow days'? false: this.TrackingFields[3].visible;

            this.TrackingFields[4].visible = this.itemType == 'survey' ? false: this.TrackingFields[4].visible;
            this.TrackingFields[0].visible = this.itemType == 'survey' ? false: this.TrackingFields[0].visible;
            this.TrackingFields[1].visible = this.itemType == 'survey' ? true: this.TrackingFields[1].visible;
            this.TrackingFields[2].visible = this.itemType == 'survey' ? true: this.TrackingFields[2].visible;
            this.TrackingFields[3].visible = this.itemType == 'survey' ? true: this.TrackingFields[3].visible;
            return this.TrackingFields.filter((field) => field.visible);
        }
    },
    methods: {
        closeModal(){
            this.$emit("close");
        },
        async myProvider(){
            const formData = {
                "itemId": this.itemId,
                "itemType": this.itemType,
                "userId": this.userId
            }
            const {data} = await SettingService.getSettingItemsTrack(formData);
            return data;
        },
        badgeColor(badge){
            let badgeClass = {
                "PENDING": "light-warning",
                "SCALED": "light-primary",
                "REACTIVATED": "light-info",
                "COMPLETED": "light-success"
            }
            return badgeClass[badge];
        },
        jsonParsing(detail){
            return JSON.parse(detail);
        }
    }
   
}
</script>