export default [
  {
    key: "item",
    label: "Name",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "level",
    label: "Level",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    visible: false,
  },
  {
    key: "created_by",
    label: "Created by",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "Created at",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "action",
    label: "Actions",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
]
