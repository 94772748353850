var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('filter-slot',{attrs:{"filter":[],"total-rows":_vm.FilterSlot.totalRows,"paginate":_vm.FilterSlot.paginate,"start-page":_vm.FilterSlot.startPage,"to-page":_vm.FilterSlot.toPage,"filter-principal":_vm.FilterSlot.filterPrincipal},on:{"reload":function($event){return _vm.$refs['claimsTable'].refresh()}},scopedSlots:_vm._u([([1, 2, 17].includes(_vm.currentUser.role_id))?{key:"buttons",fn:function(){return [_c('div',{staticClass:"ml-2"},[_c('b-button',{staticClass:"text-capitalize d-flex align-items-center",staticStyle:{"gap":"5px"},attrs:{"variant":"primary"},on:{"click":_vm.openCreateModal}},[_c('tabler-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Create New "+_vm._s(_vm.tabSelected)+" ")],1)],1)]},proxy:true}:null],null,true)},[_c('b-table',{ref:"claimsTable",staticClass:"blue-scrollbar",attrs:{"slot":"table","items":_vm.myProvider,"fields":_vm.myFields,"busy":_vm.isBusy,"primary-key":"id","responsive":"md","sticky-header":"50vh","show-empty":"","no-provider-filtering":"","per-page":_vm.FilterSlot.paginate.perPage,"current-page":_vm.FilterSlot.paginate.currentPage},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.item.status == 'ACTIVE' ? 'light-success' : 'light-warning'}},[_vm._v(_vm._s(data.item.status))]),(_vm.validateStatusAndRefund(data.item))?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            data.item.status == 'ACTIVE' ? 'Disable' : 'Enable'
          ),expression:"\n            data.item.status == 'ACTIVE' ? 'Disable' : 'Enable'\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"clickable ml-1",class:data.item.status == 'ACTIVE' ? 'text-success' : 'text-warning',attrs:{"icon":data.item.status == 'ACTIVE' ? 'ShieldIcon' : 'ShieldOffIcon',"size":"17"},on:{"click":function($event){return _vm.changeStatus(data.item)}}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Not editable'),expression:"'Not editable'",modifiers:{"hover":true,"top":true}}],staticClass:"clickable ml-1",class:data.item.status == 'ACTIVE' ? 'text-grey' : 'text-warning',staticStyle:{"cursor":"default"},attrs:{"icon":data.item.status == 'ACTIVE' ? 'ShieldIcon' : 'ShieldOffIcon',"size":"17"}})]}},{key:"cell(created_at)",fn:function(ref){
          var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(value)))])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('feather-icon',{staticClass:"text-primary clickable",attrs:{"icon":"ListIcon","size":"16"},on:{"click":function($event){return _vm.trackingModal(data.item)}}}),_c('b-button',{attrs:{"disabled":['Status to hold', 'REFUND'].includes(data.item.item),"variant":"outline"},on:{"click":function($event){return _vm.editModal(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:data.item.item == 'Status to hold' ||
                data.item.item == 'REFUND'
                  ? 'text-grey'
                  : 'text-info',attrs:{"icon":"EditIcon","size":"16","title":data.item.item == 'Status to hold' ||
                data.item.item == 'REFUND'
                  ? 'Not editable'
                  : null}})],1)],1)]}}])})],1),(_vm.showCreateModal)?_c('item-modal',{attrs:{"type":_vm.type,"claimData":_vm.claimData,"tabSelected":_vm.tabSelected},on:{"close":_vm.closeCreateModal,"refresh":function($event){return _vm.refreshTable()}}}):_vm._e(),(_vm.showTrackingModal)?_c('TrackingModal',{attrs:{"itemId":_vm.itemId,"itemType":_vm.tabSelected},on:{"close":_vm.closeTrackingModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }